/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { Fragment } from 'react';
import { formattedPrice, formatDisplayPrice } from 'libs/support/price';
import styled from 'styled-components';
import renderHTML from 'react-render-html';
import { withI18n } from 'libs/support/i18n';
import { object, func, array, string, bool, number } from 'prop-types';
import classNames from 'classnames';

const noTax = 'no';
const vendorDeliveryType = 'own';


const Dotted = styled.span`
  display: inline-block;
  margin: 0 6px;
`;

const BasketBody = ({
  order,
  orderableType,
  orderableVariations,
  translate,
  customMenuItems,
  totalQuantity,
  mobile = false,
}) => {
  const { deliveryType, vendor: { taxType: vendorTaxType } } = order;

  const isNoTaxType = vendorTaxType === noTax;
  const vendorDelivery = deliveryType === vendorDeliveryType;
  const displayPrice = (value, precision = 2) => formattedPrice(value, { withSpace: false, compact: false, decimal: precision });

  const renderItemsTotal = () => (
    <Fragment>
      <span className="basket-item-total-row">
        <span className="basket-item-total-text">{translate('ordersShowDetailsOrderCartsTotal')}</span>
        <span className="basket-item-total-value">{formatDisplayPrice(order.vSubtotalAmount)}</span>
      </span>
      <span className="basket-item-total-row">
        <span className="basket-item-total-text">{translate('ordersShowDetailsOrderCartsSmallOrderFee')}</span>
        <span className="basket-item-total-value">{formatDisplayPrice(order.vVendorSmallOrderFee)}</span>
      </span>
      {vendorDelivery &&
        <Fragment>
          <span className="basket-item-total-row">
            <span className="basket-item-total-text">{translate('ordersShowDetailsOrderCartsDeliveryFee')}</span>
            <span className="basket-item-total-value">{formatDisplayPrice(order.vDeliveryAmount)}</span>
          </span>
          <span className="basket-item-total-row">
            <span className="basket-item-total-text">
              {translate('ordersShowDetailsOrderCartsSurcharge')} {order.surchargeName}
            </span>
            <span className="basket-item-total-value">{formatDisplayPrice(order.vSurchargeAmount)}</span>
          </span>
        </Fragment>
      }
    </Fragment>
  );

  const renderTotalAmount = () => (
    <span className="basket-item-total-row grand-total-value">
      <span className="basket-item-total-text">{translate('ordersShowDetailsOrderCartsTotal')}</span>
      <span className="basket-item-total-value grand-total-value">{displayPrice(order.vTotalAmount)}</span>
    </span>
  );

  const renderTotalVariationAmount = (value) => (
    <span>
      ${formatDisplayPrice(value, 5)}
    </span>
  );

  const renderNonBillableSurchargeAmount = () => {
    if (order?.nonBillableSurchargeEnabled) {
      return (
        <Fragment>
          <span className="basket-item-total-row basket-item-total-row-last">
            <span className="basket-item-total-text">{order.nonBillableSurchargeName}</span>
            <span className="basket-item-total-value">{order.nonBillableSurchargeAmount}</span>
          </span>
        </Fragment>
      );
    }
  };

  const shouldIncludeLabelColumn = order.groupOrder && orderableVariations?.some((orderableVariation) => orderableVariation.labels.length > 0);

  return (
    <Fragment>
      { orderableVariations?.map((orderableVariation) => (
        <div key={orderableVariation.uuid} className="basket-item">
          { !mobile &&
            <span className="basket-item-qty">{ orderableVariation.quantity }</span>
          }
          <span className="basket-item-name">
            <span className="item-name">
              {orderableVariation.nameString}
              <Dotted>•</Dotted>
              {renderTotalVariationAmount(parseFloat(orderableVariation.totalPrice) / orderableVariation.quantity)}
            </span>
            { mobile &&
              <span className="basket-item-qty">Qty { orderableVariation.quantity }</span>
            }
            {orderableVariation.menuItemDisplayDescription &&
              <span className="mt-2">{renderHTML(orderableVariation.menuItemDescription)}</span>}
            {orderableVariation.includedItems && orderableVariation.includedItems.length > 0 &&
              <Fragment>
                <span className="mt-2 basket-options-label">
                  {translate('ordersShowDetailsOrderCartsIncludedItems')}
                </span>
                <ul className="tw-ml-6">
                  {orderableVariation.includedItems.map((item) => (
                    <li key={item.uuid}>
                      {item.name}
                    </li>
                  ))}
                </ul>
              </Fragment>
            }
            {(orderableVariation[`${orderableType}VariationItemAddOnOptions`].length > 0 ||
              orderableVariation[`${orderableType}VariationOptionChoices`].length > 0) &&
              <span className="mt-2 basket-options-label">{translate('ordersShowDetailsOrderCartsOptions')}</span>
            }
            {orderableVariation[`${orderableType}VariationOptionChoices`].length > 0 &&
              <Fragment>
                <ul className="tw-ml-6">
                  {orderableVariation[`${orderableType}VariationOptionChoices`].map((choice) => (
                    <li key={choice.uuid}>
                      {choice.name}
                    </li>
                  ))}
                </ul>
              </Fragment>
            }
            {orderableVariation[`${orderableType}VariationItemAddOnOptions`].length > 0 &&
              <Fragment>
                <ul className="tw-ml-6">
                  {orderableVariation[`${orderableType}VariationItemAddOnOptions`].map((choice) => (
                    <li key={choice.uuid}>
                      {`${choice.quantity} X `}
                      {choice.name}
                      {choice.totalPriceWithTax > 0 && <Fragment> • ${formatDisplayPrice(choice.totalPriceWithTax, 5)}</Fragment>}
                    </li>
                  ))}
                </ul>
              </Fragment>
            }

            {orderableVariation[`${orderableType}VariationServiceAddOnOptions`].length > 0 &&
              <Fragment>
                <span className="mt-2 basket-options-label">
                  {translate('ordersShowDetailsOrderCartsServiceAddOnOptions')}
                </span>
                <ul>
                  {orderableVariation[`${orderableType}VariationServiceAddOnOptions`].map((choice) => (
                    <li key={choice.uuid}>
                      {`x${choice.quantity} `}
                      {choice.name} • {choice.totalPriceWithTax > 0 && displayPrice(choice.totalPriceWithTax)}
                    </li>
                  ))}
                </ul>
              </Fragment>
            }

            { !mobile && orderableVariation.additionalInstructions &&
              <Fragment>
                <span className="basket-options-label">
                  {translate('ordersShowDetailsOrderCartsAdditionalInstructions')}
                </span>
                <span>{orderableVariation.additionalInstructions}</span>
              </Fragment>
            }
          </span>

          { mobile && orderableVariation.additionalInstructions &&
            <Fragment>
              <span className="basket-options-label">
                {translate('ordersShowDetailsOrderCartsAdditionalInstructions')}
              </span>
              <span className="basket-options-value">{orderableVariation.additionalInstructions}</span>
            </Fragment>
          }
          { order.groupOrder &&
            <span className="basket-item-label">
              {orderableVariation.labels.length > 0 &&
                <Fragment>
                  { mobile &&
                    <span className="label-span">
                      {translate('ordersShowDetailsOrderCartsLabel')}
                    </span>
                  }
                  { orderableVariation.labels?.map((label) => (
                    <span className="label-name" key={`${label.userName}-${orderableVariation.magicId}`}>
                      {label.userName}
                    </span>
                  ))}
                </Fragment>
              }
            </span>
          }

          <span className="basket-item-price">{displayPrice(orderableVariation.totalPrice)}</span>
        </div>
      ))}
      { customMenuItems?.map((customMenuItem) => (
        <div key={customMenuItem.uuid} className="basket-item">
          { !mobile &&
            <span className="basket-item-qty">{customMenuItem.quantity}</span>
          }
          <span className="basket-item-name">
            <span className="item-name">
              {customMenuItem.name}
              <Dotted>• </Dotted>
              {renderTotalVariationAmount(customMenuItem.price)}
            </span>
            { customMenuItem.description &&
              <Fragment>
                <span>{customMenuItem.description}</span>
              </Fragment>
            }
            { customMenuItem.specialInstructions &&
              <Fragment>
                <span className="mt-2 basket-options-label">
                  {translate('ordersShowDetailsOrderCartsSpecialInstructions')}
                </span>
                <span>{customMenuItem.specialInstructions}</span>
              </Fragment>
            }
          </span>
          { mobile &&
            <span className="basket-item-qty">Qty {customMenuItem.quantity}</span>
          }
          { shouldIncludeLabelColumn &&
            <span className="basket-item-label" />
          }
          <span className="basket-item-price">{displayPrice(customMenuItem.price)}</span>
        </div>
      ))}
      { mobile ?
        <div className="basket-total">
          <span className="basket-item-total">
            { renderItemsTotal() }
            { !isNoTaxType &&
              <span className={
                classNames(
                  'basket-item-total-row',
                  { 'basket-item-total-row-last': !order.nonBillableSurchargeEnabled },
                )}
              >
                <span className="basket-item-total-text">{translate('ordersShowDetailsOrderCartsGst')}</span>
                <span className="basket-item-total-value">{displayPrice(order.vTaxAmount) }</span>
              </span>
            }
            { renderNonBillableSurchargeAmount() }
            { renderTotalAmount() }
          </span>
        </div>
      :
        <div className="basket-total">
          <span className="basket-item-grand-total">{totalQuantity}</span>
          <span className="basket-item-grand-total-text">{translate('ordersShowDetailsOrderCartsTotalQuantity')}</span>
          <span className="basket-item-total">
            { renderItemsTotal() }
            { !isNoTaxType &&
              <span className={
                classNames(
                  'basket-item-total-row',
                  { 'basket-item-total-row-gst': !order.nonBillableSurchargeEnabled },
                )}
              >
                <span className="basket-item-total-text">{translate('ordersShowDetailsOrderCartsGst')}</span>
                <span className="basket-item-total-value">{formatDisplayPrice(order.vTaxAmount) }</span>
              </span>
            }
            { renderNonBillableSurchargeAmount() }
            { renderTotalAmount() }
          </span>
        </div>
      }
      { order.additionalInstructions &&
        <div className="basket-delivery-instructions">
          <span className="basket-delivery-instructions-text">
            {translate('ordersShowDetailsOrderCartsDeliveryInstructions')}
          </span>
          <span className="basket-delivery-instructions-value">{ order.additionalInstructions }</span>
        </div>
      }
    </Fragment>
  );
};

BasketBody.propTypes = {
  order: object.isRequired,
  orderableType: string.isRequired,
  orderableVariations: array.isRequired,
  translate: func.isRequired,
  customMenuItems: array,
  mobile: bool,
  totalQuantity: number,
};

export default withI18n(BasketBody);
