/* eslint max-len: 0, no-global-assign: 0, no-restricted-globals: 0 */

import React from 'react';
import { bool, object, string } from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import Sheet from '@caterspot/react-label-sheet';
import { blankish } from 'libs/support/string';

import { withI18n } from 'libs/support/i18n';

const Container = styled.div`
  > section {
    background: transparent !important;
  }
`;

const LabelType = {
  default: 'meal',
  individual: 'planned_meal',
};

/**
 * LeftPanel
 */
const LeftPanel = ({
  labels,
  template,
  type,
  show,
  userAgent,
  versi,
}) => {
  const { settings } = template;
  const labelTag = LabelType[type] || 'planned_meal';
  const location = new URL(document.location.toString()).searchParams;
  const onlySheet = location.get('only_sheet') !== null;

  return (
    <Container className={classNames('tw-transform tw-transition-all', {
      'tw-hidden': blankish(userAgent),
      '!tw-block': !show && blankish(userAgent),
      'tw-translate-x-[280px]': show && !onlySheet,
    })}
    >
      <Sheet
        labels={labels.toJS()}
        editable={false}
        labelTag={labelTag}
        version={versi}
        setting={{
          meal: {
            labelPerRow: settings.label_per_row,
            labelPerCol: settings.label_per_col,
          },
          page: settings.page_size,
          labelHeight: settings.label_height,
          labelWidth: settings.label_width,
          labelPaddingRight: settings.label_margin_left_right,
          labelPaddingLeft: settings.label_margin_left_right,
          labelPaddingTop: settings.label_margin_top_bottom,
          labelPaddingBottom: settings.label_margin_top_bottom,
          sheetPaddingLeft: settings.sheet_margin_left_right,
          sheetPaddingRight: settings.sheet_margin_left_right,
          sheetPaddingTop: settings.sheet_margin_top_bottom,
          sheetPaddingBottom: settings.sheet_margin_top_bottom,
          sortBy: settings.sort_by,
        }}
      />
    </Container>
  );
};

LeftPanel.propTypes = {
  labels: object.isRequired,
  template: object.isRequired,
  show: bool.isRequired,
  type: string.isRequired,
  versi: string.isRequired,
  userAgent: string,
};

export default withI18n(LeftPanel);
