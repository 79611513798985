/* eslint camelcase: 0 */

import Immutable from 'immutable';

import * as actionTypes from '../constants/outletSelectorConstants';

export const $$initialState = Immutable.fromJS({
  outletsSelected: [],
  outletsSelectedIds: [],
  vendorId: '',
  outlets: [],
  vendors: [],
  mobile: false,
});

export default function outletSelectorReducer($$state = $$initialState, action = null) {
  const { type, response } = action;

  switch (type) {
    case actionTypes.SELECT_OUTLETS_SUCCESS: {
      return $$state.withMutations(state => (
        state
          .set('outletsSelectedIds', Immutable.fromJS(response.outlet_selected_ids))
          .set('vendorSelected', Immutable.fromJS(response.vendor_selected))
          .set('vendorId', Immutable.fromJS(response.vendor_selected?.table?.uuid || ''))
      ));
    }

    default: {
      return $$state;
    }
  }
}
